import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useCookies } from 'react-cookie';

import useAuth from '../../services/auth/useAuth';
import Button from '../Button';
import checkAuth from '../../services/auth/checkAuthSyncronous';

let LANG = localStorage.getItem('lang') || 'fr';

export default function LoginNav() {
	const history = useHistory();
	const [cookies] = useCookies(['authToken']);
	const jwt = cookies['authToken'];

	let isAuthenticated = checkAuth(jwt);

	const isQuestionnaire =
		window.location.pathname === '/' ||
		window.location.pathname === '/question' ||
		window.location.pathname === '/single';

	useEffect(() => {
		LANG = localStorage.getItem('lang') || 'fr';
	}, []);

	if (isQuestionnaire && isAuthenticated) {
		return (
			<div className="login-nav">
				{isAuthenticated && window.userPhone && (
					<>
						<Button
							className="btn text login-nav-info"
							onClick={() => {
								if (
									window.confirm(
										LANG === 'fr'
											? 'Confirmer la déconnexion ?'
											: 'Confirm logout?'
									)
								)
									history.push('/logout');
							}}
						>
							{LANG === 'fr' ? 'Authentifié !' : 'Authenticated!'}{' '}
							<strong>
								{LANG === 'fr' ? 'Se déconnecter ?' : 'Logout?'}
							</strong>
						</Button>
					</>
				)}
				<Button
					href="#"
					className="btn text login-nav-info"
					onClick={() => {
						localStorage.setItem(
							'lang',
							localStorage.getItem('lang') === 'fr' ? 'en' : 'fr'
						);
						history.go(0);
					}}
				>
					<strong>
						{localStorage.getItem('lang') === 'fr' ? 'EN' : 'FR'}
					</strong>
				</Button>
			</div>
		);
	} else {
		return <></>;
	}
}
