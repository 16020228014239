import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

export default function useAuth() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [cookies] = useCookies(['authToken']);

	const checkAuthentication = async () => {
		const jwt = cookies['authToken'];
		if (jwt != null) {
			try {
				const url = `${process.env.REACT_APP_STRAPI_URL}/api/users/me`;

				return await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${jwt}`,
						'Content-Type': 'application/json',
					},
				})
					.then(async (res) => {
						const res_json = await res.json();

						if (res_json.error?.status == '401') {
							setIsAuthenticated(false);
						} else {
							window.userEmail = res_json?.email;
							window.userId = res_json?.id;
							window.userPhone = res_json?.phone;
							setIsAuthenticated(true);
						}
					})
					.catch((e) => {
						console.error('Error:', e);
						setIsAuthenticated(false);
					});
			} catch (err) {
				console.log(err);
				setIsAuthenticated(false);
			}
		} else setIsAuthenticated(false);
	};

	useEffect(() => {
		checkAuthentication();
	}, []);

	return isAuthenticated;
}
