import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import LogRocket from 'logrocket';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

LogRocket.init(process.env.REACT_APP_LOGROCKET_APP || '9utkmq/declach-local');

Sentry.init({
	dsn: process.env.SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<CookiesProvider>
			<App />
		</CookiesProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
