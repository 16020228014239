import React from 'react';
import Loadable from 'react-loadable';

/**
 * return loader UI
 */
function Loading() {
	return <div />;
}
/**
 * routes of whole app is defined below
 */
const Home = Loadable({
	loader: () => import('../views/home'),
	loading: Loading,
});

const Question = Loadable({
	loader: () => import('../views/questions'),
	loading: Loading,
});

const QuestionForSingle = Loadable({
	loader: () => import('../views/single'),
	loading: Loading,
});

const Register = Loadable({
	loader: () => import('../views/register'),
	loading: Loading,
});

const Login = Loadable({
	loader: () => import('../views/login'),
	loading: Loading,
});

const Logout = Loadable({
	loader: () => import('../views/logout'),
	loading: Loading,
});

const Profile = Loadable({
	loader: () => import('../views/profile'),
	loading: Loading,
});

const Request = Loadable({
	loader: () => import('../views/request'),
	loading: Loading,
});

const Verified = Loadable({
	loader: () => import('../views/verified'),
	loading: Loading,
});

const VerificationRequired = Loadable({
	loader: () => import('../views/verificationRequired'),
	loading: Loading,
});

const routes = [
	{
		path: '/',
		exact: true,
		name: 'Home',
		component: Home,
	},
	{
		path: '/question',
		exact: true,
		name: 'Question',
		component: Question,
	},
	{
		path: '/single',
		exact: true,
		name: 'Question for Single',
		component: QuestionForSingle,
	},
	{
		path: '/register',
		exact: true,
		name: 'Register',
		component: Register,
	},
	{
		path: '/login',
		exact: true,
		name: 'Login',
		component: Login,
	},
	{
		path: '/logout',
		exact: true,
		name: 'Logout',
		component: Logout,
	},
	{
		path: '/profile',
		exact: true,
		name: 'Profile',
		component: Profile,
	},
	{
		path: '/request/:id',
		exact: true,
		name: 'Request',
		component: Request,
	},
	{
		path: '/verified',
		exact: true,
		name: 'Verified',
		component: Verified,
	},
	{
		path: '/verification-required',
		exact: true,
		name: 'Verification Required',
		component: VerificationRequired,
	},
];

export default routes;
