import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import mainComponent from '../mainComponent/mainComponent';
import '../assets/css/coreui-icons.css';
import '../assets/css/simple-line-icons.css';
import './App.css';

import getCompany from '../services/strapi/company.service';
const Css = require('json-to-css');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	async componentDidMount() {
		if (
			window.location.host.indexOf('decla.ch') >= 0 && // updates url for prod
			window.location.host.indexOf('staging.decla.ch') < 0 && // doesn't update url for staging
			window.location.protocol !== 'https:'
		) {
			window.location.protocol = 'https';
		}

		const lang =
			localStorage.getItem('lang') || process.env.REACT_APP_APP_LANG;

		// when testing, the current company subdomain is specified in the env vars
		let subdomainPrefix = process.env.REACT_APP_COMPANY_NAME;

		// when in staging or production, we get the subdomain to retrieve the company info
		if (
			process.env.REACT_APP_ENV === 'production' ||
			process.env.REACT_APP_ENV === 'staging'
		) {
			var host = window.location.host;
			subdomainPrefix = host.split('.')[0];
		}

		this.company = await getCompany(lang, subdomainPrefix);
		window.company = this.company;

		/**
		 * Dynamically load CSS override
		 */
		const overrideCSS = window.company?.overrideCSS || {};
		if (overrideCSS) {
			const css = Css.of(overrideCSS);
			this.OverrideStyle = createGlobalStyle`${css}`;
		}

		/**
		 * dynamically adapt the tab/page title and favico from strapi company page data
		 * */
		document.title = `${
			this.company.name || 'Decla.ch'
		} | ONLINE DECLARATION`;

		const favicoUrl =
			this.company.favico?.data?.attributes.url || '/favicon.ico';
		if (favicoUrl) {
			var link = document.querySelector("link[rel~='icon']");

			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}

			link.href = favicoUrl;
		}

		// var metaDesc = document.querySelector("meta[name~='description']");
		// var metaImg = document.querySelector("link[rel~='apple-touch-icon']");
		// var metaOGImg = document.querySelector("meta[property~='og:image']");

		// // Update description if found in the company data from Strapi
		// const companyMetaDescription =
		// 	this.company.sharingLinkPreviewDescription;
		// if (companyMetaDescription) metaDesc.content = companyMetaDescription;

		// // Update preview image if found in the company data from Strapi
		// const companyMetaImage =
		// 	this.company.sharingLinkPreviewImage?.data?.attributes.url;
		// if (companyMetaImage) {
		// 	metaImg.href = companyMetaImage;
		// 	metaOGImg.content = companyMetaImage;
		// }

		this.setState({ loading: false });
	}

	render() {
		if (this.state.loading) return <div>loading... </div>;
		return (
			<React.Fragment>
				<this.OverrideStyle />
				<BrowserRouter>
					<Switch>
						<Route path="/" name="Home" component={mainComponent} />
					</Switch>
				</BrowserRouter>
			</React.Fragment>
		);
	}
}

export default App;
