import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import getCompany from '../services/strapi/company.service';
import routes from '../config/routes';
import './mainComponent.css';
import '../assets/css/global.scss';
import customize from '../assets/css/customisation';
import LoginNav from '../components/user/loginNav';

class mainComponent extends Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	loading: true,
		// };
		this.company = window.company;
		customize();

		// by default define the current lang as browser
		const langNavigator =
			window.navigator.userLanguage || window.navigator.language;

		if (!localStorage.getItem('lang')) {
			if (langNavigator && /^en\b/.test(langNavigator))
				localStorage.setItem('lang', 'en');
			else localStorage.setItem('lang', 'fr');

			// try and get lang from params, if set then replace default nav lang
			const params = new URL(document.location).searchParams;
			const langParams = params.get('lang');
			if (langParams) localStorage.setItem('lang', langParams);
			console.log('lang', localStorage.getItem('lang'));
		}
	}

	render() {
		return (
			<CookiesProvider>
				<div className="App">
					<div className="body-wrapper">
						<div className="main">
							<div id="popup-root" />
							<div
								className="section-wrapper"
								id="sectionWrapper"
								style={{
									backgroundColor:
										this.company?.backgroundColor,
								}}
							>
								<Switch>
									{routes.map((route, idx) => {
										if (route.component) {
											let modalPopup =
												route.path.indexOf(':') !== -1
													? true
													: false;
											return (
												<Route
													key={idx}
													path={route.path}
													modal={modalPopup}
													exact={route.exact}
													name={route.name}
													render={(props) => {
														return (
															<route.component
																{...props}
															/>
														);
													}}
												/>
											);
										}
									})}
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</CookiesProvider>
		);
	}
}

export default mainComponent;
