const qs = require('qs');

const getCompany = async (lang = 'fr', subdomainPrefix) => {
	const query = qs.stringify(
		/**
		 * WARNING: this is overriden with this content from strapi controllers to prevent undesired "populate *"
		  	populate: {
				logo: '*',
				logoMobile: '*',
				favico: '*',
				company_target: '*',
				backgroundImages: {
					populate: '*',
				},
				sharingLinkPreviewImage: '*',
			},
		 */
		{
			locale: lang,
			filters: {
				subdomainPrefix: {
					$eq: subdomainPrefix,
				},
			},
			populate: {
				logo: '*',
				logoMobile: '*',
				favico: '*',
				company_target: '*',
				backgroundImages: {
					populate: '*',
				},
				sharingLinkPreviewImage: '*',
			},
		},
		{
			encodeValuesOnly: true, // prettify URL
		}
	);

	const url = `${process.env.REACT_APP_STRAPI_URL}/api/companies?${query}`; //locale=${lang}&filters[subdomainPrefix][$eq]=${subdomainPrefix}&populate=logo,logoMobile,company_target,backgroundImages`;

	return await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then(async (res) => {
			const res_json = await res.json();
			const company = res_json?.data[0]?.attributes;
			const companyId = res_json?.data[0]?.id;
			company['id'] = companyId;

			return company;
		})
		.catch((e) => {
			console.error('Error:', e);
		});
};

export default getCompany;
