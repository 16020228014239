export default async function checkAuth(jwt) {
	if (jwt != null) {
		try {
			const url = `${process.env.REACT_APP_STRAPI_URL}/api/users/me`;

			return await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${jwt}`,
					'Content-Type': 'application/json',
				},
			})
				.then(async (res) => {
					const res_json = await res.json();

					if (res_json.error?.status == '401') {
						return false;
					} else {
						window.userEmail = res_json?.email;
						window.userId = res_json?.id;
						window.userPhone = res_json?.phone;
						return true;
					}
				})
				.catch((e) => {
					console.error('Error:', e);
					return false;
				});
		} catch (err) {
			console.log(err);
			return false;
		}
	} else return false;
}
