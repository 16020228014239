import React from 'react';

export default function Button(props) {
	const c = window.company;

	const styles = {};

	if (c.buttonBorder) {
		styles['border'] = '1px solid white';
	} else {
		styles['border'] = 'none';
	}
	if (c.buttonBorderRadius) styles['borderRadius'] = c.buttonBorderRadius;
	if (c.buttonBorderThickness)
		styles['borderWidth'] = c.buttonBorderThickness + 'px';
	if (c.buttonColor) styles['backgroundColor'] = c.buttonColor;
	if (c.buttonPadding && props.className.indexOf('small') === -1)
		styles['padding'] = c.buttonPadding;

	return (
		<button className={props.className} style={styles} {...props}>
			{' '}
			{props.children}
		</button>
	);
}
